<script>
import {useMainStore} from '@/stores/mainStore';
import {debounce} from '../functions';

function* getTextNodesUsingClassName() {
  for (const e of document.getElementsByClassName('search-term')) {
    if (e.firstChild !== null) {
      yield e.firstChild;
    }
  }
}

function* getTextNodesUsingXpath() {
  const textNodes = document.evaluate(
      '//div[@id=\'all-hotels-list\']//text()',
      document.getElementsByClassName('all-hotels-list').item(0),
      null,
      XPathResult.UNORDERED_NODE_SNAPSHOT_TYPE,
  );

  for (let i = 0; i < textNodes.snapshotLength; i++) {
    let textNode = textNodes.snapshotItem(i);
    yield textNode;
  }
}

export default {
  props: {
    isDropdownMode: Boolean,
    whereAreYouGoing: String,
    associateBookingEmployeeHomeHotelIdsArray: {
      type: Array,
      default: [],
    },
  },
  components: {
    debounce
  },
  data() {
    return {
      pickerId: 'hotels-picker',
      delimiter: window.bookingWidget.config
          ? window.bookingWidget.config.searchTagDelimiter ?? '@@'
          : '@@',
      showErrorsBool: false,
      selectedHotelString: this.whereAreYouGoing,
      selectedAriaHotelString: this.whereAreYouGoing,
      hotelSelectedBool: false,
      hotelIdInputString: '',
      allHotelsArray: window.bookingWidget
          ? window.bookingWidget.hotelsArray
          : [
            {
              name: 'state 1',
              cities: [
                {name: 'test 1', hotels: [{name: 'test & test', id: '1'}]},
              ],
            },
          ],
      numberOfVisibleHotelsInt: 0,
      hotelFilterDebounce: null
    };
  },
  methods: {
    updateSelectedHotel(button) {
      this.selectedHotelString = button.textContent.trim();
      this.selectedAriaHotelString = this.selectedHotelString + " hotel selected";
      this.hotelIdInputString = button.dataset.hotelId;
      this.hotelSelectedBool = true;
      this.showErrorsBool = false;
      this.filterHotelList('');
      const hotel = {id: Number.parseInt(button.dataset.hotelId), name: this.selectedHotelString};
      if("hasRoomFilters" in button.dataset){
        hotel.views = button.dataset.views.split(this.delimiter);
        hotel.hasRoomFilters = true;
      } else {
        hotel.hasRoomFilters = false;
      }
      hotel.roomName = button.dataset.roomName ?? null;
      this.mainStore.selectedHotel = hotel;
      this.mainStore.hideSelectGuest = "hideSelectGuest" in button.dataset;
      this.mainStore.nonOmniHotel = "nonOmniHotel" in button.dataset;
      this.mainStore.closePickerAndFocusPickerButton();
      if (this.$refs.hotelSearchElement) {
        this.$refs.hotelSearchElement.value = '';
      }
    },
    removeHighlights() {
      document.querySelectorAll('mark.matched-text').forEach((e) => {
        const parentElement = e.parentElement;
        e.replaceWith(e.textContent);
        parentElement.normalize();
      });
    },
    highlightText(searchString, getFunction = getTextNodesUsingClassName) {
      this.removeHighlights();

      if (searchString.trim() === '') {
        return;
      }
      for (const textNode of getFunction()) {
        this.addMarkTagToText(textNode, searchString);
      }
    },
    reverseString(string) {
      return [...string].reverse().join('');
    },
    addMarkTagToText(textNode, searchString) {
      searchString = searchString.trimStart();
      const searchTerm = new RegExp(
          this.reverseString(
              searchString.replaceAll(/ *, *(\w)/g, '|$1').replaceAll(/ *, */g, ''),
          ),
          'gi',
      );
      const testNodeString = this.reverseString(textNode.textContent);

      // let matchedTextArray;
      for (const matchedTextArray of testNodeString.matchAll(searchTerm)) {
        const searchedTextRange = document.createRange();
        searchedTextRange.setStart(
            textNode,
            testNodeString.length -
            matchedTextArray.index -
            matchedTextArray[0].length,
        );
        searchedTextRange.setEnd(
            textNode,
            testNodeString.length - matchedTextArray.index,
        );

        const markMatchedTextElement = document.createElement('mark');
        markMatchedTextElement.classList.add('matched-text');
        searchedTextRange.surroundContents(markMatchedTextElement);
      }
    },
    filterHotelList(searchString) {
      this.highlightText(searchString);

      this.$refs.allHotelsListElement.querySelectorAll('ul.state-list *').forEach((e) => (e.hidden = false));

      this.checkForSearchTerm(
          this.$refs.allHotelsListElement,
          'ul.state-list > li',
          new RegExp(searchString.trim(), 'i'),
      );

      this.updateNumberOfVisibleHotelsInt();
      this.updateScreenReaderText();

      this.$refs.allHotelsListElement
      .getElementsByClassName("no-results")
      .item(0).hidden = this.numberOfVisibleHotelsInt > 0;

    },
    checkForSearchTerm(parentElement, querySelector, searchTerm) {
      const childrenHide = [];
      parentElement.querySelectorAll(querySelector).forEach((listElement) => {
        let hide = true;
        const checkStringArray = [listElement.firstElementChild.textContent];
        if (listElement.firstElementChild.dataset.searchTags) {
          checkStringArray.push(
              ...listElement.firstElementChild.dataset.searchTags.split(this.delimiter),
          );
        }
        const matchNotFoundBool = !checkStringArray.some((s) => searchTerm.test(s));
        if (matchNotFoundBool) {
          hide = this.checkForSearchTerm(
              listElement,
              querySelector + ' > ul > li',
              searchTerm,
          );
        }
        else {
          hide = false;
        }
        childrenHide.push(hide);
        listElement.hidden = hide;
      });
      return !childrenHide.includes(false);
    },
    setSelectedHotel(hotelIdInt) {
      if (hotelIdInt) {
        const hotelButtonElement = this.$refs.allHotelsListElement.querySelector(
            `button[data-hotel-id="${hotelIdInt}"]`,
        );
        if (hotelButtonElement !== null) {
          this.updateSelectedHotel(hotelButtonElement);
        }
      }
    },
    updateNumberOfVisibleHotelsInt() {
      this.numberOfVisibleHotelsInt = document.querySelectorAll('.city-hotels-list__hotel:not([hidden])').length;
    },
    updateScreenReaderText() {
      if(this.$refs.screenReaderTextEl){
        this.$refs.screenReaderTextEl.textContent = '';
        this.$refs.screenReaderTextEl.textContent = this.noResultsBool ? 'no results' : this.numberOfVisibleHotelsInt +
            ' ' + (this.numberOfVisibleHotelsInt === 1 ? 'result' : 'results') + ' available';
        setTimeout(() => this.$refs.screenReaderTextEl.textContent = '', 100);
      }
    },
    showHotelNotSelectedError() {
      this.showErrorsBool = !this.hotelSelectedBool;
      return !this.showErrorsBool;
    },
    dealingAssociateBookingsEmployeeHomeHotel(hotels) {
      for (let i = 0; i < hotels.length; i++) {
        if (this.associateBookingEmployeeHomeHotelIdsArray.includes(hotels[i].id.toString())) {
          hotels.splice(i, 1);
        }
      }
      return hotels.length > 0;
    },
  },
  computed: {
    ...Pinia.mapStores(useMainStore),
    pickerOpenBool() {
      return (this.mainStore.pickerVisibleId === this.pickerId);
    },
    noResultsBool() {
      return this.numberOfVisibleHotelsInt <= 0;
    },
  },
  mounted() {
    this.hotelFilterDebounce = debounce(this.filterHotelList, 100);
    this.setSelectedHotel(this.mainStore.getFormData(this.mainStore.fieldNames.hotel));
    this.updateNumberOfVisibleHotelsInt();
  },
};
</script>
<template>
  <li
      ref="hotelPickerElement"
      :class="'picker picker--hotels'"
      :id="pickerId">
    <div
        id="hotel-picker-title"
        class="picker__title picker__title--search-icon"
        :class="{'picker__title--input-in-title': !isDropdownMode && mainStore.isPickerOpen($refs.hotelPickerElement)}"
    >
      <button
          class="picker__title__button"
          type="button"
          aria-expanded="false"
          :aria-controls="pickerId+'-dropdown'"
          @click="mainStore.togglePicker($refs.hotelPickerElement);"
          :data-picker-id="pickerId"
      >
        <span class="picker__title__text" :hidden="!isDropdownMode && mainStore.isPickerOpen($refs.hotelPickerElement)">
          {{ selectedHotelString }}
        </span>
        <span class="sr-only" :hidden="!hotelSelectedBool">
           hotel selected
        </span>
      </button>
    </div>
    <fieldset :id="pickerId+'-dropdown'" :class="'picker__dropdown picker__dropdown--hotels'">
      <legend class="sr-only">Select Hotel</legend>
      <button class="picker__close-button close-dropdown" type="button" aria-label="close hotel picker"
              @click="mainStore.closePickerAndFocusPickerButton"></button>
      <input
          class="hotel-picker-input"
          type="text"
          data-focus-on-invalid-id="hotel-search-input"
          :name="mainStore.fieldNames.hotel"
          :value="hotelIdInputString"
          @invalid.prevent="showHotelNotSelectedError($event.target);"
          @change="showHotelNotSelectedError($event.target)"
          required
          aria-hidden="true"
      />
      <input v-if="mainStore.formData.scroll_to_room !== undefined" type="hidden" name="scroll_to_room"
             :value="mainStore.formData.scroll_to_room"/>
      <template>
        <section aria-label="hotels filter" v-show="!mainStore.isModifyingBooking">
          <div class="sr-only" v-if="!mainStore.isModifyingBooking">The following text field filters the results that follow as you type.</div>
          <input
              ref="hotelSearchElement"
              id="hotel-search-input"
              type="search"
              class="hotels-search-input"
              :class="{'hotels-search-input--in-title': !isDropdownMode,
                                       'search-icon': isDropdownMode,
                    }"
              :placeholder="selectedHotelString"
              @input="hotelFilterDebounce($event.target.value)"
              aria-controls="all-hotels-list"
              :aria-label="selectedAriaHotelString"
              :aria-invalid="showErrorsBool"
              v-if="!mainStore.isModifyingBooking"
              :[showErrorsBool ? 'aria-describedby' : null]="'hotel-list-error'"

          />
          <div ref="screenReaderTextEl" class="sr-only" aria-live="assertive" v-if="!mainStore.isModifyingBooking"></div>
          <strong
              id="hotel-list-error"
              class="error"
              aria-live="polite"
              :hidden="!showErrorsBool"
              v-if="!mainStore.isModifyingBooking"
          >
            Please select a hotel from the list below
          </strong>
          <h2 class="hotels-picker-title" v-if="!mainStore.isModifyingBooking">All Hotels & Resorts</h2>
          <ul id="all-hotels-list" class="state-list" ref="allHotelsListElement">
            <li class="no-results" hidden>
              <strong>No results</strong>
            </li>
            <li v-for="(state, sindex) in allHotelsArray" :key="sindex">
              <h3 class="state-list__state">
            <span
                class="search-term"
                :data-search-tags="state.searchTags ?? ''"
            >{{ state.name }}</span
            >
              </h3>
              <ul class="city-list">
                <li v-for="(city, cindex) in state.cities" :key="cindex">
                  <h4
                      class="city-list__city"
                      :data-search-tags="city.searchTags ?? ''"
                      v-if="dealingAssociateBookingsEmployeeHomeHotel(city.hotels)"
                  >
                <span
                    class="search-term"
                >{{ city.name }}</span
                >
                  </h4>
                  <ul class="city-hotels-list">
                    <li
                        v-for="(hotel, hindex) in city.hotels"
                        :key="hindex"
                        class="city-hotels-list__hotel"
                        v-if="!associateBookingEmployeeHomeHotelIdsArray.includes(hotel.id.toString())"
                    >
                      <button
                          @click="updateSelectedHotel($event.currentTarget)"
                          class="city-hotels-list__button search-term"
                          type="button"
                          :data-hotel-id="hotel.id"
                          :data-views="hotel.views"
                          :data-has-room-filters="hotel.hasRoomFilters"
                          :data-room-name="hotel.roomName"
                          :data-hide-select-guest="hotel.hideSelectGuest"
                          :data-non-omni-hotel="hotel.nonOmniHotel"
                          :data-search-tags="
                      (hotel.searchTags ?? '') +
                      `${delimiter} ${state.name}, ${city.name}, ${hotel.name}` +
                      `${delimiter} ${hotel.name}, ${city.name}, ${state.name}`"
                      >
                        {{ hotel.name }}
                      </button>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
          </ul>
        </section>
      </template>
      <template v-if="mainStore.isModifyingBooking">
          <span class="hotels-search-input hotels-search-input--in-title"
                v-show="!isDropdownMode">{{ selectedHotelString }}</span>
        <span>{{ mainStore.t.modifyingBooking }} {{ selectedHotelString }}</span>
      </template>
    </fieldset>
  </li>
</template>
