function numberFallback(int, fallback, smallestValueInt = 0) {
  int = Number.parseInt(int);
  return Number.isNaN(int) === false && int >= smallestValueInt ? int : fallback;
}

export const useMainStore = Pinia.defineStore('main', () => {
  const pickerVisibleElem = window.Vue.ref('');
    const interconnectingRoomsChecked = window.Vue.ref(false);
  const pickerVisibleId = window.Vue.computed(
      () => pickerVisibleElem.value.id ?? '');
  const flexibleDates = window.Vue.ref(0);
  const rootId = window.Vue.ref('');
  let formData = window.Vue.reactive({});
  const selectedHotel = window.Vue.ref({});
  const hideSelectGuest = window.Vue.ref(false);
  const nonOmniHotel = window.Vue.ref(false);
  const maxAdults = window.Vue.ref(1);
  let maxChildren = window.Vue.reactive({});
  const isModifyingBooking = window.Vue.ref(false);

  const searchTagDelimiter =
      window.bookingWidget.config.searchTagDelimiter ?? '@@';

  const fieldNames = window.Vue.reactive({
    hotel: 'hotel',
    arrival: 'arrival',
    departure: 'departure',
    nights: 'nights',
    flexisearch: 'flexi_search',
    rooms: 'rooms',
    adults: 'adults',
    children: 'children',
    childAge: 'childAge',
    rates: 'ratePlanCategory',
    code: 'code',
    codeType: 'codeType',
    iataNumber: 'iataNumber',
    token: 'token',
    filterView: 'filterView',
    filterBedroom: 'filterBedroom',
  });

  const t = {};

  const isFlexibleDates = window.Vue.computed(
      () => window.Vue.ref(flexibleDates));

  function _addGuestDetails() {
    if (formData.rooms) {
      const adults = {};
      const childAge = {};
      const children = {};
      for (let i = 1; i <= formData.rooms; i++) {
        adults[i] =
            numberFallback(formData[`${fieldNames.adults}[${i}]`], 2, 1);
        const childrenInt = numberFallback(
            formData[`${fieldNames.children}[${i}]`],
            0,
        );
        children[i] = childrenInt;
        const childAges = {};
        for (let j = 1; j <= childrenInt; j++) {
          childAges[j] =
              numberFallback(formData[`${fieldNames.childAge}[${i}][${j}]`],
                  '');
        }
        childAge[i] = childAges;
      }
      formData.adults = adults;
      formData.childAge = childAge;
      formData.children = children;
    }
  }

  function _addFilters() {
    if (formData.rooms) {
      const filterView = [];
      const filterBedroom = [];
      for (let i = 1; i <= formData.rooms; i++) {
        filterView[i - 1] = formData[`${fieldNames.filterView}[${i}]`] ?? "";
        filterBedroom[i - 1] = formData[`${fieldNames.filterBedroom}[${i}]`] ?? "";
      }
      formData.filterView = filterView;
      formData.filterBedroom = filterBedroom;
    }
  }

  function checkRoomsData() {
    formData.rooms = numberFallback(formData.rooms, 1, 1);
    if(formData.rooms > window.searchWidget.rooms){
      formData.rooms = window.searchWidget.rooms;
    }
  }

  function checkNumberData() {
  }

  function addFormData(
      request = window.p3Hotels.request ?? {},
      params = new URLSearchParams(window.location.search),
  ) {
    if (request.hotelID) {
      request.hotel = request.hotelID;
    }
    if (request.cityID) {
      request.city = request.cityID;
    }
    if (request.code !== undefined && request.code === '') {
      delete request.code;
    }
    formData = Object.assign(
        formData,
        Object.fromEntries(params.entries()),
        request,
    );
    formData[fieldNames.flexisearch] = window.location.href.includes(
        '/flexisearch/1/')
        ? '1'
        : getFormData(fieldNames.flexisearch, '0');
    if (window.p3Hotels.request === undefined) {
      _addGuestDetails();
    }
    else {
      formData.adults = {};
      for (const adultKey of Object.keys(request.adults ?? {})) {
        formData.adults[adultKey] = numberFallback(request.adults[adultKey], 2, 1);
      }
      if(request.childAges === undefined){
        request.childAges = {};
      }
      formData.children = {};
      formData.childAge = {};
      for (const childrenKey of Object.keys(request.children ?? {})) {
        formData.children[childrenKey] = numberFallback(request.children[childrenKey], 0, 0);
        formData.childAge[childrenKey] = {};
        if(request.childAges[childrenKey] === undefined){
          request.childAges[childrenKey] = {};
        }
        for(let childAgeIndex = 1; childAgeIndex <= formData.children[childrenKey]; childAgeIndex++ ){
          formData.childAge[childrenKey][childAgeIndex] = numberFallback(request.childAges[childrenKey][childAgeIndex] ?? "", "", 0);
        }
      }
    }
    _addFilters();
    if (formData.rooms === undefined && formData.adults) {
      formData.rooms = Object.keys(formData.adults).length;
    }

    checkRoomsData();
  }

  function getFormData(key, fallback = null) {
    return formData[key] ? formData[key] : fallback;
  }

  function setFieldNames(newFieldNames) {
    for (let fieldName in fieldNames) {
      fieldNames[fieldName] = newFieldNames[fieldName];
    }
  }

  //Picker functions
  function openPicker(pickerElem) {
    closePicker();

    pickerElem.classList.add('show');
    setTogglePickerButtonsAriaExpanded(pickerElem.id, true);
    pickerVisibleElem.value = pickerElem;
    return true;
  }

  function closePicker() {
    if (pickerVisibleElem.value.classList !== undefined) {
      pickerVisibleElem.value.classList.remove('show');
      setTogglePickerButtonsAriaExpanded(pickerVisibleElem.value.id, false);
      pickerVisibleElem.value = '';
      return true;
    }
    return false;
  }

  function togglePicker(pickerElem) {
    if (isPickerOpen(pickerElem)) {
      return closePicker();
    }
    else {
      return openPicker(pickerElem);
    }
  }

  function isPickerOpen(pickerElem) {
    return pickerElem === pickerVisibleElem.value;
  }

  function closePickerAndFocusPickerButton() {
    const pickerVisibleLocalElem = pickerVisibleElem.value;
    if (closePicker()) {
      focusPickerButton(pickerVisibleLocalElem);
    }
  }

  function closePickerIfClickedOutside(pickerElem, target) {
    if (isPickerOpen(pickerElem) &&
        pickerElem !== target &&
        !pickerElem.contains(target)) {
      closePicker();
      return true;
    }
    return false;
  }

  function closePickerIfEscPressed(pickerElem, target) {
    if (pickerElem &&
        isPickerOpen(pickerElem) &&
        pickerElem.contains(target)) {
      closePicker();
      focusPickerButton(pickerElem);
      return true;
    }
    return false;
  }

  function focusPickerButton(pickerVisibleElem) {
    const pickerButtonElem = pickerVisibleElem.querySelector(
        'button.picker__title__button');
    if (pickerButtonElem) {
      pickerButtonElem.focus();
    }
  }

  function closeAllPickersIfClickedOutside(target) {
    Array.from(document.getElementsByClassName('picker')).forEach((el) => {
      closePickerIfClickedOutside(el, target);
    });
  }

  function setTogglePickerButtonsAriaExpanded(pickerId, bool) {
    document.querySelectorAll(`[data-picker-id='${pickerId}']`).
        forEach(el => el.setAttribute('aria-expanded', bool));
  }

  return {
    searchTagDelimiter,
    pickerVisibleId,
    pickerVisibleElem,
    flexibleDates,
    rootId,
    formData,
    t,
    isModifyingBooking,
    selectedHotel,
    hideSelectGuest,
    nonOmniHotel,
    maxAdults,
    maxChildren,
    isFlexibleDates,
    fieldNames,
    addFormData,
    getFormData,
    setFieldNames,
    openPicker,
    closePicker,
    togglePicker,
    isPickerOpen,
    closePickerAndFocusPickerButton,
    closePickerIfEscPressed,
    closeAllPickersIfClickedOutside,
      interconnectingRoomsChecked,
  };
});
